import React from 'react';
import MainLayout from "../../Components/MainLayout/MainLayout";
import { P1 } from "../../Styles/Common/CommonStyles";



function Faq() {
    return (
        <MainLayout>
            <P1>FAQ coming soon</P1>
        </MainLayout>
    )
}
export default Faq;