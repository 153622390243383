import React from 'react';
import MainLayout from "../../Components/MainLayout/MainLayout";
import { P1 } from "../../Styles/Common/CommonStyles";



function Usermanual() {
    return (
        <MainLayout>
            <P1>User manual coming soon</P1>
        </MainLayout>
    )
}
export default Usermanual;