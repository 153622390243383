import styled from "styled-components";

export const Heading = styled.text`
  color: #fff;
  font-size: 36px;
  margin-top: 18px;
  letter-spacing: 0.5px;
`;

export const HeaderSmall = styled.text`
  color: #fff;
  font-size: 28px;
  margin-top: 18px;
  letter-spacing: 0.5px;
`;
export const P1 = styled.p`
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.5px;
  text-align: center;
`;
export const P2 = styled.p`
  color: #fff;
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.5px;
`;
export const HeadingBald = styled.p`
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: bold;
`;
export const SubHeadingBald = styled.p`
  color: #fff;
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: bold;
`;
export const ParagraphWhite = styled.p`
  color: #fff;
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding-bottom: 10px;
`;
export const PrivacyPolicyAndTermsOfServiceOuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const PrivacyPolicyAndTermsOfServiceInnerDiv = styled.div`
  padding-left: 10%;
  padding-right: 10%;
`;
export const LinkOrange = styled.a`
  color: #ffc17d;
`;
